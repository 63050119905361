@tailwind base;
@tailwind components;
@tailwind utilities;

@import url(//fonts.googleapis.com/earlyaccess/notosanskr.css);

body {
  margin: 0;
  font-family: 'Noto Sans KR', 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.text-ku {
  --tw-text-opacity: 1;
  color: rgb(144 36 55 / var(--tw-text-opacity));
}

.bg-ku {
  --tw-bg-opacity: 1;
  background-color: rgb(144 36 55 / var(--tw-bg-opacity));
}

.text-lg-wrapper p {
  font-size: 1.125rem; /* 18px */
  line-height: 1.75rem; /* 28px */
}

.text-link-wrapper a {
  color: #0275d8;
}

.toastui-editor-contents p {
  margin: 0 !important;
}

.toastui-editor-contents {
  font-family: 'Noto Sans KR', 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif !important;
}

@media (max-width: 480px) {
  html {
    font-size: 13px;
  }
}

@media (min-width: 481px) and (max-width: 767px) {
  html {
    font-size: 14px;
  }
}