@media only screen and (max-width: 480px) {
  .toastui-editor-dropdown-toolbar {
      max-width: none;
      flex-wrap: wrap;
      height: fit-content;
  }
}

.toastui-editor-contents {
  font-size: inherit;
}

.ProseMirror {
  font-size: inherit;
}